import { STORE_REMOTE_REPORT_ROUTE } from 'constants/navigation';
import React, { useEffect, useState } from 'react';
import useRemoteReportsContext from 'contexts/remote-reports';
import DashboardContent from 'components/Dashboard/DashboardContent';
import BetaBadge from 'components/Misc/BetaBadge';
import ToolbarDateReportDropdown from 'components/Reports/ToolbarDateReportDropdown';
import { IS_BETA_RELEASE } from 'constants/constants';
import useCategoryRemoteReport from 'endpoints/remote-reports/useCategoryRemoteReport';
import { getHomeGreeting } from 'helper/dashboard/greeting';
import useAuth from 'contexts/auth/auth.context';
import useShopContext from 'contexts/shop/shop.context';
import { Container } from 'containers/AppLayout/AppLayout.style';
import {
  Wrapper,
  Header,
  HeaderContent,
} from 'components/Dashboard/Dashboard.style';
import Loader from 'components/Loader/Loader';
import { Caption, Link } from 'styles/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const StoreReportLink: React.FC = () => (
  <Link href={STORE_REMOTE_REPORT_ROUTE} target={'_blank'}>
    <FontAwesomeIcon icon={faExternalLinkAlt} />
    &nbsp; View Store Report
  </Link>
);

const Dashboard: React.FC = (props) => {
  const { authState } = useAuth();
  const { user } = authState;
  const { shopState } = useShopContext();

  const { timeFrame: timeFrameProps } = useRemoteReportsContext();
  const { fromDate, toDate, getDateRangeDescription } = timeFrameProps;

  const { report, loading, error } = useCategoryRemoteReport({
    categoryId: 1,
    fromDate,
    toDate,
  });

  const [greeting, setGreeting] = useState(getHomeGreeting(user.firstName));
  useEffect(
    () => setGreeting(getHomeGreeting(user.firstName)),
    [user.firstName]
  );

  let subtitleDateRange = getDateRangeDescription(false, {
    relativePrefix: true,
  });

  return (
    <Container id={'dashboard'}>
      <Wrapper>
        <Header>
          <HeaderContent>
            <h1>{greeting}</h1>

            <Caption>
              Here's {shopState.name}'s performance {subtitleDateRange}.
            </Caption>

            <StoreReportLink />
          </HeaderContent>

          {!loading && (
            <ToolbarDateReportDropdown
              buttonAlign={'right'}
              descriptionProps={{ includeCustom: false }}
              {...timeFrameProps}
            />
          )}
        </Header>

        {loading && <Loader style={{ margin: '0 auto 20px' }} />}

        {!loading && <DashboardContent report={report} />}
      </Wrapper>
    </Container>
  );
};

export default Dashboard;
